import React from "react";

export default function ProjectCard(props) {
  const infoPart = (
    <div className={"work-content " + (props.link? "" : "flex-column-grow")}>
      <div className={"row " + (props.link? "" : "flex-column-grow")}>
        <div
          className={
            props.link
              ? "col-sm-8 flex-column-grow"
              : "col-sm-12 flex-column-grow"
          }
        >
          <h2 className="w-title flex-column-grow">{props.title}</h2>
          <div className="w-more">
            <span className="w-ctegory flexible">{props.technologies}</span>
          </div>
        </div>
        {props.link ? (
          <div className="col-sm-4">
            <div className="flex-column-grow" />
            <div className="w-like">
              <a href={props.link}><span className="ion-link"/></a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <div className="col-md-4 flex-row">
      <div className="work-box">
        <a className="flex-column-grow" href={props.projectLink}>
          <div className="work-img">
            <img src={props.image} alt="project screenshot" className="img-fluid" />
          </div>
          {infoPart}
        </a>
      </div>
    </div>
  );
}
