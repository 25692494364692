import React from "react";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "basicWeb_skill", content: "HTML5/CSS3/JavaScript", porcentage: "90%", value: "90" },
        {
          id: "React_skill",
          content: "React",
          porcentage: "90%",
          value: "90"
        },
        {
          id: "TS_skill",
          content: "TypeScript",
          porcentage: "90%",
          value: "90"
        },
        {
          id: "C#/.Net Core_skill",
          content: "C#/.Net Core",
          porcentage: "85%",
          value: "85"
        },
        {
          id: "Docker_skill",
          content: "Docker",
          porcentage: "75%",
          value: "75"
        },
        {
          id: "SQL_skill",
          content: "SQL",
          porcentage: "75%",
          value: "75"
        },
        {
          id: "MongoDB_skill",
          content: "MongoDB",
          porcentage: "75%",
          value: "75"
        },
        {
          id: "Python_skill",
          content: "Python",
          porcentage: "70%",
          value: "70"
        },
        {
          id: "ROS_skill",
          content: "ROS",
          porcentage: "70%",
          value: "70"
        },
        {
          id: "Java_skill",
          content: "Java",
          porcentage: "70%",
          value: "70"
        },
        {
          id: "server config_skill",
          content: "Server config",
          porcentage: "65%",
          value: "65"
        },
        {
          id: "C++_skill",
          content: "C++",
          porcentage: "60%",
          value: "60"
        },
        {
          id: "C_skill",
          content: "C",
          porcentage: "50%",
          value: "50"
        },

      ],
      about_me: [
        {
          id: "first-p-about",
          content:
           "Hi, I am Niels De Cat. I have studied application development at the applied university of science PXL with a specialization in AI & Robotics."
        },
        {
          id: "second-p-about",
          content:
           "Over the past few years I did several projects. Some of them for my own, some of them as a student job, and some for university projects. By doing a student job at Online Orbit, I acquired my top skills. For example, Typescript, React, NodeJS, MongoDB, and so on. You can evaluate my skills from the projects I did at this student job."
        },
        {
          id: "third-p-about",
          content:
          "Moreover, I not only learned basic programing skills but also specialized in AI & Robotics in university, such as ROS, Pathfinding, SLAM, different sensors like LiDAR, ultrasonic, GPS, and Docker to control the different parts of the robot individually while staying connected with ROS. You can also view my projects from the portfolio section below."
        },
        {
          id: "third-p-about",
          content:
          "Lately, I have finished my studies, and I am looking for my first full time job. Feel free to have a look through the projects I made. In case you are interested in what you see or you are looking for someone with this skillset, there is a contact section below. Please do not hesitate to send me a message on LinkedIn or an email with the form down below."
        }
      ]
    };
  }

  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div
                        className="col-sm-6 col-md-5"
                        style={{ margin: "0 auto" }}
                      >
                        <div
                          className="about-img"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            className="img-fluid rounded b-shadow-a"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="skill-mf">
                      {/* <p className="title-s">Skill</p> */}
                      {this.state.skills.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <span>{skill.content}</span>{" "}
                            <span className="pull-right">
                              {skill.porcentage}
                            </span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h5 className="title-left">About Me</h5>
                      </div>
                      {this.state.about_me.map(content => {
                        return (
                          <p className="lead" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
